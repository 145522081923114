import { memo } from 'react';
import Image from 'next/image';
import { Link2Icon } from '@radix-ui/react-icons';
import { BridgeContainer } from '@/components/BridgeContainer/BridgeContainer';
import { FaqSidebar } from '@/components/Faq/FaqSidebar';
import { pyrope, garnetHolesky, redstone, CHAIN_ID_TO_CHAIN } from '@/chains/chains';
import { activeChainId } from '@/common';

const chain = CHAIN_ID_TO_CHAIN[activeChainId];

const RELAY_LINK_URL = {
  [redstone.id]: 'https://relay.link/bridge/redstone',
  [garnetHolesky.id]: 'https://testnets.relay.link/bridge/garnet?fromChainId=17000',
  [pyrope.id]: 'https://testnets.relay.link/bridge/pyrope?fromChainId=11155111',
} as const;

const SUPERBRIDGE_URL = {
  [redstone.id]: 'https://superbridge.app/redstone-mainnet',
  [garnetHolesky.id]: 'https://superbridge.app/lattice-testnet',
} as const;

const BRIDGE_OPTIONS = [
  {
    name: 'Relay.link',
    url: RELAY_LINK_URL[activeChainId as keyof typeof RELAY_LINK_URL],
    bgColor: '#1C172B',
    image: {
      width: 93,
      height: 28,
      url: '/bridges/relaylink.svg',
    },
    features: [
      'Fast relayer bridge',
      'Instant bridging',
      'Percentage fee, cheaper for small transaction',
      'Supports ETH',
    ],
    team: 'Reservoir',
    networks: [redstone.id, garnetHolesky.id, pyrope.id],
  },
  {
    name: 'Superbridge',
    url: SUPERBRIDGE_URL[activeChainId as keyof typeof SUPERBRIDGE_URL],
    bgColor: '#305FF6',
    image: {
      width: 262,
      height: 256,
      url: '/bridges/superbridge.webp',
    },
    features: [
      'Standard bridge',
      '7-days withdrawal delay',
      'Only gas fees, cheaper for large transactions',
      'Supports ETH, USDC',
    ],
    team: 'Blob Engineering',
    networks: [redstone.id, garnetHolesky.id],
  },
  {
    name: 'Brid.gg',
    url: 'https://brid.gg/redstone?token=ETH',
    bgColor: '#0F152A',
    image: {
      width: 147,
      height: 167,
      url: '/bridges/bridgg.webp',
    },
    features: [
      'Standard bridge',
      '7-days withdrawal delay',
      'Only gas fees, cheaper for large transactions',
      'Supports ETH',
    ],
    team: 'Brid.gg Network',
    networks: [redstone.id],
  },
] as const;

export default memo(function WithdrawPage() {
  return (
    <BridgeContainer>
      <div className="mb-16 flex-col lg:flex lg:h-full lg:flex-row">
        <div className="grow">
          <div className="px-4 sm:px-4 lg:px-8">
            <p className="mb-8 mt-8 text-white/60">
              Go to{' '}
              {BRIDGE_OPTIONS.filter((bridge) => bridge.networks.includes(activeChainId))
                .map((bridge) => bridge.name)
                .join(', ')
                .replace(/, ([^,]*)$/, ' or $1')}{' '}
              to bridge your assets to and from {chain?.name || 'Redstone'}. For questions, see our
              FAQ.
            </p>

            <div className="grid grid-cols-1 gap-8">
              {BRIDGE_OPTIONS.map((bridge) => {
                if (!bridge.networks.includes(activeChainId)) {
                  return null;
                }

                return (
                  <a
                    key={bridge.name}
                    href={bridge.url}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="group flex flex-col items-center justify-center border border-white/10 sm:flex-row lg:flex-row"
                  >
                    <div
                      className={
                        'flex h-60 w-full flex-col items-center justify-center duration-500 group-hover:brightness-150 sm:h-full sm:w-3/5 md:w-full lg:w-3/5 lg:max-w-[350px] lg:flex-row'
                      }
                      style={{
                        backgroundColor: bridge.bgColor,
                      }}
                    >
                      <Image
                        alt="Superbridge"
                        width={bridge.image.width}
                        height={bridge.image.height}
                        src={bridge.image.url}
                      />
                    </div>

                    <div className="flex w-full  flex-row items-start justify-between bg-white/10 py-4 pl-4 group-hover:bg-white/15 sm:py-6 sm:pl-8">
                      <div className="flex flex-col">
                        <h2 className="font-mono text-xl uppercase">
                          {bridge.name}{' '}
                          <Link2Icon width={20} height={20} className="-mt-1 inline-block" />
                        </h2>
                        <p className="mt-2 text-lg opacity-60">By {bridge.team}</p>

                        <h4 className="mb-1 pt-6 opacity-80">Features:</h4>
                        <ul className="list-disc pl-4 opacity-80">
                          {bridge.features.map((feature, idx) => {
                            return <li key={idx}>{feature}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        </div>

        <FaqSidebar />
      </div>
    </BridgeContainer>
  );
});
